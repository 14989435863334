import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { TransformDomain } from "../types";
import { ReclaimSku } from "../Users";
import { ReclaimApi, Team as TeamDto, TeamMemberView as TeamMemberDto } from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export type Team = Override<
  TeamDto,
  {
    readonly id: number;
  }
>;

export type TeamMember = Override<
  TeamMemberDto,
  {
    readonly edition: ReclaimSku;
    readonly status: TeamMemberViewStatus
  }
>;

export const dtoToTeamMember = (dto: TeamMemberDto): TeamMember => {
  return { 
    ...dto,
    edition: dto.edition as unknown as ReclaimSku,
    status: dto.status as unknown as TeamMemberViewStatus
  };
};

export class TeamDomain extends TransformDomain<Team, TeamDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam();
  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberDto[]) => response.map(dtoToTeamMember));
}
